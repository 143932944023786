<template>
	<section class="theme-page-search">
		<SearchForm
			v-if="showDesktopForm"
			:client="client"
			:images="images"
		/>
		<SearchFormModal
			v-if="!showDesktopForm"
			ref="searchFormModal"
			header="Suche anpassen"
			apply-button="Suchen"
			cancel-button="Abbrechen"
		/>
	</section>
</template>

<script lang="ts" setup>
import SearchForm from '@lmt-rpb/SearchForm/SearchForm.vue';
import SearchFormModal from '@lmt-rpb/SearchFormModal/SearchFormModal.vue';
import { computed, onMounted, ref } from 'vue';
import { EventBus } from '@global-js/event-bus';
import { useStore } from '@/components/common/store';

interface Props {
	client?: ClientType,
	images?: BackgroundImages | undefined,
}

const props = withDefaults(defineProps<Props>(), {
	client: 'at',
	images: undefined,
});

const searchFormModal = ref<InstanceType<typeof SearchFormModal>>();

const store = useStore();

const isDesktop = computed(() => store.state.config.isDesktop);

const isHomePage = computed(() => store.state.config.pageType === 'homePage');

const showDesktopForm = computed(() => isDesktop.value || isHomePage.value);

onMounted(() => {
	EventBus.$on('ThemePageSearch:openSearch', () => searchFormModal.value?.openModal());
});

</script>
