<template>
	<a
		class="pill"
		:class="{'pill--filled': filled}"
		:href="url"
		:title="pillTitle"
		data-ga-event="select_content"
		data-ga-content_type="Quickpill"
		:data-ga-item_id="pillTitle"
	>
		{{ label }}
	</a>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

interface Props {
	label: string,
	url: string,
	title: string,
	filled: boolean
}
const props = defineProps<Props>();

const pillTitle = computed(() => (props.title ? props.title : props.label));

</script>

<style lang="scss" scoped>
@mixin filled-style {
	color: $color-white;
	background-color: $color-primary;
	border-color: $color-primary;
}

.pill {
	font-size: $font-small;
	font-weight: 700;
	color: $color-primary;
	text-decoration: none;
	border: 0.1rem solid $color-primary-l4;
	border-radius: $border-radius-xx-large;
	padding: 0.6rem 1.4rem;
	white-space: nowrap;
}

.pill--filled,
.pill:active {
	@include filled-style;
}
</style>
