import { createApp, h } from 'vue';
import { determineClient } from '@utils/utils';
import ThemePageSearch from '@lmt-rpb/ThemePageSearch/ThemePageSearch.vue';
import { EventBus } from '@global-js/event-bus';
import { paramsToForm, parseSearchMaskAndFilterSettings } from '@components/common/services/transform';
import { dashedDate } from '@/js/utils/dateUtils';
import { SearchFormDataType, SuggestionDataType } from '@/interfaces/search-form';
import store from '../common/store';

// TODO: create interface for all allowed params in another file. To see all allowed params check: /app/src/restplatzboerse/all/components/common/services/url.ts 
interface SearchMaskQueryData {
	destination?: string,
	dur: string,
	ddate: string,
	rdate: string,
	ibe: string,
	depap: string
}

// eslint-disable-next-line no-use-before-define
initSearch();

/**
 * Mounts searchmask
 * @param mountPoint HTMLElement - the element for root to mount
 * @param isHeaderSearch boolean - search in the header
 */
async function mountSearch(mountPoint: HTMLElement) {
	try {
		await initStore();
	} catch (e) {
		console.error(e);
	}
	const isAutoGeneratedTopic = document.body.classList.contains('page_topic');

	const Search = createApp({
		render: () => h(ThemePageSearch, {
			client: determineClient(document.location.href),
			images: mountPoint.dataset.images ? JSON.parse(mountPoint.dataset.images) : null,
		}),
	});

	Search.mount(mountPoint);

	if (isAutoGeneratedTopic) EventBus.$emit('Autocomplete:updatedActiveItem'); // Init fixed button
}

function initSearch() {
	const mountPoint = document.querySelector('#search-form__main');
	if (mountPoint) {
		mountSearch(mountPoint as HTMLElement);
	}
}

async function initStore() {
	const searchMaskDataSelector = (document.querySelector('#search-mask-data') as HTMLElement);
	const searchMaskData = searchMaskDataSelector.dataset as unknown as SearchMaskQueryData;
	const searchMaskUrlParams = prepareQueryParams(searchMaskData);

	const formData = paramsToForm(searchMaskUrlParams);
	const updateFormData = prepareUpdateFormData(formData, searchMaskData.destination);
	await store.commit('searchMask/updateFormData', updateFormData);
	updateTopicPageStoreByQuery();
	EventBus.$emit('Store:Initialized');
}

function prepareQueryParams(searchMaskData: SearchMaskQueryData) {
	const {
		dur, ddate, rdate, ibe, depap
	} = searchMaskData;

	const ddateFormatted = convertToDateIfNumber(ddate);
	const rdateFormatted = convertToDateIfNumber(rdate);

	return {
		dur, ddate: ddateFormatted, rdate: rdateFormatted, ibe, depap
	};
}

function prepareUpdateFormData(formData: Partial<SearchFormDataType>, destination: string | undefined): Partial<SearchFormDataType> {
	const {
		offerDuration, travelDuration, onlyHotel, departure
	} = formData;
	const updateFormData: Partial<SearchFormDataType> = { offerDuration, travelDuration, onlyHotel };
	if (departure) {
		updateFormData.departure = departure;
	}
	if (destination) {
		updateFormData.destination = JSON.parse(destination) as SuggestionDataType;
	}
	return updateFormData;
}

// move to a topic page js file since is specific to it.
function updateTopicPageStoreByQuery() {
	const urlDataSelector = (document.querySelector('.url-params-data') as HTMLElement)?.dataset;
	const parsedUrlParams = parseSearchMaskAndFilterSettings(urlDataSelector ?? {}).urlParams;
	if (parsedUrlParams) {
		store.commit('searchMask/updateFormData', parsedUrlParams);
	}
}

function convertToDateIfNumber(date: string) {
	if (Number(date)) {
		return dashedDate(new Date(Number(date)));
	}
	return date;
}
